import { apis } from '@doltech/core/lib/api/api.config';
import { apiCacheKeys, apiUrls } from '@doltech/core/lib/api/api.urls';
import { components } from '@doltech/pageManagement/dto';
import {
  ElasticRequestBody,
  EslasticResponse,
  TransformedElasticFacets,
  TransformedElasticPage,
  TransformedElasticResult,
} from '@doltech/types';
import {
  DATE_FORMAT,
  LandingTestStatusEnum,
  MyTestFilterStatusEnum,
  SkillEnum,
  TestTypeEnum,
} from '@doltech/utils/lib/constants';
import { transformElasticResponse } from '@doltech/utils/lib/elastic';
import { genEmptyPagable } from '@doltech/utils/lib/object';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';

import { getMyTestIds } from '../../home-page/api/dol-v1_5.api';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

export interface UpdateOrderingMutation {
  skill: SkillEnum;
  dropRowId: string;
  dropOrdering?: number;
}

/**
 * Add `elastic` field
 */
export type CustomPracticeTestWithOverviewDTO =
  components['schemas']['PracticeTestWithOverviewDTO'] & {
    elastic?: TransformedElasticResult;
  };
export type CustomPagePracticeTestWithOverviewDTO =
  components['schemas']['PagePracticeTestWithOverviewDTO'] & {
    content: CustomPracticeTestWithOverviewDTO[];
  };

export type FetchPublicPracticeTestElasticResult = {
  data: CustomPagePracticeTestWithOverviewDTO;
  facets: TransformedElasticFacets;
  page: TransformedElasticPage;
};

export const fetchSectionList = async (params) => {
  const query = { ...params };
  if (query.fromDate) {
    query.fromDate = moment(query.fromDate, DATE_FORMAT).toISOString();
  }

  if (query.toDate) {
    query.toDate = moment(query.toDate, DATE_FORMAT).endOf('day').toISOString();
  }
  query.page -= 1;
  const { data } = await apis.pageManagement.get<components['schemas']['PagePracticeTestDTO']>(
    apiUrls.pageManagement.ALL_PRACTICE_TEST,
    query
  );
  return data;
};

export const fetchPracticeTestTrending = async (skill: SkillEnum) => {
  const { data } = await apis.pageManagement.get<components['schemas']['PracticeTestDTO'][]>(
    apiUrls.pageManagement.GET_TRENDING_PRACTICE_TESTS,
    {
      skill,
    }
  );
  return data;
};

export const fetchPracticeTestDetail = async (testSectionId: string) => {
  const { data } = await apis.pageManagement.get<components['schemas']['PracticeTestDTO']>(
    apiUrls.pageManagement.GET_PRACTICE_TEST_DETAIL,
    {
      testSectionId,
    }
  );
  return data;
};

export const useFetchPracticeTests = (params) => {
  return useQuery(
    [apiCacheKeys.pageManagement.GET_PRACTICE_TEST, params],
    () => fetchSectionList(params),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      refetchOnMount: true,
      retry: false,
      keepPreviousData: true,
    }
  );
};

export const fetchPublicPracticeTest = async (
  params
): Promise<components['schemas']['PagePracticeTestWithOverviewDTO']> => {
  const { data } = await apis.publicPageManagement.get<
    components['schemas']['PagePracticeTestWithOverviewDTO']
  >(apiPublicUrls.pageManagement.GET_PRACTICE_TESTS, params);
  return data;
};

export const fetchPublicPracticeTestElastic = async (
  payload: ElasticRequestBody,
  skill
): Promise<FetchPublicPracticeTestElasticResult> => {
  const filterPayload = cloneDeep(payload);

  const statusFilterIndex = payload.filters.all.findIndex((o) => o?.status);
  const haveStatusFilter = statusFilterIndex > -1;

  let myTestIds = [];
  if (haveStatusFilter) {
    const filterStatusValue = filterPayload.filters.all[statusFilterIndex].status;
    switch (filterStatusValue) {
      case MyTestFilterStatusEnum.NEW: {
        const res: any = await getMyTestIds({
          testType: TestTypeEnum.PRACTICE_TEST,
          skill,
        });
        myTestIds = res?.content || [];
        if (!filterPayload.filters.none) {
          filterPayload.filters.none = [];
        }
        filterPayload.filters.none = filterPayload.filters.none.filter((obj) => !obj?.id);
        filterPayload.filters.none = [
          ...filterPayload.filters.none,
          {
            id: myTestIds as any,
          },
        ];
        break;
      }
      case MyTestFilterStatusEnum.IN_PROGRESS:
      case MyTestFilterStatusEnum.COMPLETED: {
        const res: any = await getMyTestIds({
          testType: TestTypeEnum.PRACTICE_TEST,
          statuses: [filterStatusValue],
          skill,
        });
        myTestIds = res?.content || [];
        filterPayload.filters.all = filterPayload.filters.all.filter((obj) => !obj?.id);
        filterPayload.filters.all = [
          ...filterPayload.filters.all,
          {
            id: myTestIds as any,
          },
        ];
        break;
      }
      default:
    }
  }

  filterPayload.filters.all = filterPayload.filters.all.filter((o) => !o?.status && !o.sort);
  const { data: response } = await apis.publicSearchTransform.post<EslasticResponse>(
    apiPublicUrls.searchTransform.GET_LANDING_FILTER_ELASTIC,
    filterPayload
  );

  const { results, page, facets } = transformElasticResponse(response);

  const ids = results.map((r) => r.id);
  let data;

  if (ids.length > 0) {
    data = results;
  } else {
    data = genEmptyPagable({ size: payload.page.size });
  }

  return {
    data,
    page,
    facets,
  };
};

export const updateOrdering = ({ skill, dropRowId, dropOrdering }: UpdateOrderingMutation) =>
  apis.pageManagement.put(
    apiUrls.pageManagement.PUT_UPDATE_PRACTICE_TEST_ORDERING(dropRowId, dropOrdering),
    {},
    {
      params: {
        skill,
      },
    }
  );

export const updateOrderingToTop = ({ skill, dropRowId }: UpdateOrderingMutation) =>
  apis.pageManagement.put(
    apiUrls.pageManagement.PUT_UPDATE_PRACTICE_TEST_ORDERING_TO_TOP(dropRowId),
    {},
    {
      params: {
        skill,
      },
    }
  );

export const updateOrderingToBottom = ({ skill, dropRowId }: UpdateOrderingMutation) =>
  apis.pageManagement.put(
    apiUrls.pageManagement.PUT_UPDATE_PRACTICE_TEST_ORDERING_TO_BOTTOM(dropRowId),
    {},
    {
      params: {
        skill,
      },
    }
  );

export const updateListingStatus = ({
  id,
  status,
}: {
  id: string;
  status: LandingTestStatusEnum;
}) =>
  apis.pageManagement.put(apiUrls.pageManagement.PUT_UPDATE_PRACTICE_TEST_LISTING_STATUS(id), {
    status,
  });
